import styled from "@emotion/styled";
import { graphql, PageProps } from "gatsby";
import React from "react";
import Image from "../components/Image";
import Page from "../components/Page";
import { PageTitle } from "../components/PageTitle";
import { Paragraph } from "../components/Paragraph";
import SEO from "../components/SEO";
import theme from "../theme";
import { innerHtmlFromPrismicRaw } from "../util";

export const query = graphql`
  {
    prismicBiography {
      data {
        page_title {
          text
        }
        image_title {
          text
        }
        image_credit_link
        image_credit_name
        biography_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        body {
          ... on PrismicBiographyBodyBiographyParagraph {
            id
            primary {
              text {
                raw
              }
            }
            slice_type
          }
        }
      }
    }
  }
`;

export const StyledBioSection = styled.div`
  margin: 0 auto;
  padding: ${theme.space[1]} ${theme.space[4]};
  max-width: 650px;

  > p {
    margin-block-end: 0;
  }
`;

const bioParagraphRawsFromQuery = (data: any) => {
  return (data.prismicBiography.data.body as any[]).map(
    (b) => b.primary.text.raw
  );
};

// TODO: Can we/should we better type the page props?
const BiographyPage: React.FC<PageProps<any>> = ({ data }) => {
  const imageData =
    data.prismicBiography.data.biography_image.localFile.childImageSharp.fluid;
  const imageTitle = data.prismicBiography.data.image_title.text;
  const imageCreditTitle = data.prismicBiography.data.image_credit_name;
  const imageCreditLink = data.prismicBiography.data.image_credit_link;
  const bioTextParagraphs = bioParagraphRawsFromQuery(data);

  return (
    <Page>
      <SEO title="Biography" />
      <PageTitle>{data.prismicBiography.data.page_title.text}</PageTitle>
      <Image
        title={imageTitle}
        credit={{ title: imageCreditTitle, link: imageCreditLink }}
        data={imageData}
      />
      <div style={{ marginTop: theme.space[4] }} />
      {bioTextParagraphs.map((p, idx) => (
        <StyledBioSection key={`biography-paragraph-lh-${idx}`}>
          <Paragraph innerHTML={innerHtmlFromPrismicRaw(p)} />
        </StyledBioSection>
      ))}
    </Page>
  );
};

export default BiographyPage;
